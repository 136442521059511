import React from 'react'
import Link from 'next/link'
import { withRouter } from 'next/router'
import dynamic from 'next/dynamic'
const CustomImage = dynamic(() => import('./CustomImage'));
import { MdMenu } from '@react-icons/all-files/md/MdMenu';

class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            route: '',
        };
    }

    componentDidMount() {
        document.querySelector('.menu-btn')?.addEventListener('click', this.mobileNavToggle);
        // Set route variable
        this.setState({
            route: this.props.router.route
        });
    }

    componentWillUnmount() {
        document.querySelector('.menu-btn').removeEventListener('click', this.mobileNavToggle);
    }

    mobileNavToggle = () => {
        document.querySelector('.menu-btn')?.classList.toggle('active');
        document.querySelector('.mobile-nav-links')?.classList.toggle('show');
    }

    render() {

        return (
            <header className="top-header">
                {/* Desktop */}
                <div className="nav-wrapper">
                    <a className="nav-logo" href="/">
                        <CustomImage srcSetPaths={[{path: '/brand/logo.png?tr=w-120,f-avif', type: 'image/avif'},{path: '/brand/logo.png?tr=w-120,f-auto', type: 'image/webp'}]}
                            mainSrc="/brand/logo.png?tr=w-120,f-png" alt="Collegify Logo" height="42px" width="120px" imgCDN loading="eager" />
                    </a>
                    <nav className='middle d-none d-lg-block'>
                        <ul className='nav-links'>
                            <li className={(this.state.route === '/Undergraduate' ? 'active' : '')}><Link href="/undergraduate"><a>UG</a></Link></li>
                            <li className={(this.state.route === '/Postgraduate' ? 'active' : '')}><Link href="/postgraduate"><a>PG</a></Link></li>
                            <li className={(this.state.route === '/MBA' ? 'active' : '')}><Link href="/mba"><a>MBA</a></Link></li>
                            <li className={(this.state.route === '/CareerMapping' ? 'active' : '')}><Link href="/career-mapping"><a>Career Mapping</a></Link></li>
                            {/* <li className={(this.state.route === '/coaching' ? 'active' : '')}><Link href="/coaching"><a>Classroom Coaching</a></Link></li> */}
                            <li className={(this.state.route === '/Contact' ? 'active' : '')}><Link href="/contact"><a>Contact Us</a></Link></li>
                        </ul>
                    </nav>
                    <ul className="nav-links nav-right">
                        <li className='d-none d-lg-block'>
                            <a href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI}>
                                E-Learning
                            </a>
                        </li>
                        <li>
                            <a role="link" aria-label="Book a Consultation"
                                className="btn btn-primary nav-btn-resp text-white" data-bs-toggle="modal" data-bs-target="#freeConsultationModal">
                                Book A Consultation
                            </a>
                        </li>
                        <li>
                            <button className="menu-btn d-block d-lg-none" aria-label="Toggle Menu">
                                <MdMenu />
                            </button>
                        </li>
                    </ul>
                </div>

                {/* Mobile NavMenu */}
                <nav className="mobile-nav-links">
                    <ul className='nav-links'>
                        <li className={(this.state.route === '/undergraduate' ? 'active' : '')}><Link href="/undergraduate"><a>UG</a></Link></li>
                        <li className={(this.state.route === '/postgraduate' ? 'active' : '')}><Link href="/postgraduate"><a>PG</a></Link></li>
                        <li className={(this.state.route === '/mba' ? 'active' : '')}><Link href="/mba"><a>MBA</a></Link></li>
                        <li className={(this.state.route === '/career-mapping' ? 'active' : '')}><Link href="/career-mapping"><a>Career Mapping</a></Link></li>
                        <li className={(this.state.route === '/contact' ? 'active' : '')}><Link href="/contact"><a>Contact Us</a></Link></li>
                        <li className="d-md-none d-block"><a href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI}>E-Learning</a></li>
                    </ul>
                </nav>

            </header>
        )
    }
}

export default withRouter(Navigation);